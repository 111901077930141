import React, { useState } from 'react';
import { Button, ButtonGroup, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import {
  StatusDocumentoDigital,
  TiposGruposDocumentoDigital,
} from '~/constant';
import * as api from '~/Services/Service';
import Outsider from '../../Outsider';
import ModalSignatario from '../ModalSignatario';

const EdicaoSignatario = ({
  signatario,
  edicaoAvancada,
  podeRealizarAcaoPreAprovacao,
  grupo,
  podeGerenciar,
  status,
  onEditarSignatario,
  beginEditarTestemunha,
  removerSignatario,
  deletarSignatario,
  incluirTestemunhas,
  index,
  setIsBusy,
  isBusy,
  habilitarNotificacao,
}) => {
  const [signatarioSelecionado, setSignatarioSelecionado] = useState({});
  const [showModalEditarSignatario, setShowModalEditarSignatario] = useState(
    false
  );

  function beginEditarSignatario(signatario) {
    setSignatarioSelecionado(signatario);
    setShowModalEditarSignatario(true);
  }

  function toggleConfirmDeleteSignatario(index, isDeleting) {
    onEditarSignatario(index, null, e => (e.isDeleting = isDeleting));
  }

  function setValidacaoSignatario(index, value) {
    onEditarSignatario(index, { exigir_documento_com_foto: value });
  }

  function toggleIsSignatario(index) {
    onEditarSignatario(index, null, e => (e.signatario = !e.signatario));
  }

  async function notificarSignatario(codigoSignatario) {
    setIsBusy(true);
    try {
      const response = await api.notificarSignatario(codigoSignatario);
      onEditarSignatario(index, { data_ultima_notificacao: response });
      toast.success('Signatário notificado com sucesso.');
    } catch (err) {
      console.error(err);
      toast.error(err.message || 'Falha ao notificar o signatário');
    } finally {
      setIsBusy(false);
    }
  }

  async function editarSignatario(values) {
    setIsBusy(true);
    try {
      const request = { codigo: signatarioSelecionado.codigo, ...values };
      const response = await api.editarSignatario(request);
      toast.success('Signatário editado com sucesso.');
      onEditarSignatario(index, response);
      setSignatarioSelecionado({});
      setShowModalEditarSignatario(false);
    } catch (err) {
      console.error(err);
      toast.error(err.message || 'Falha ao editar o signatário');
    } finally {
      setIsBusy(false);
    }
  }

  return (
    <>
      <div className="ml-auto d-flex flex-column border-left pl-2">
        {edicaoAvancada &&
          grupo.tipo === TiposGruposDocumentoDigital.Compradores &&
          signatario.assina_contrato &&
          signatario.is_conjuge_socio &&
          podeRealizarAcaoPreAprovacao && (
            <Button
              variant={signatario.signatario ? 'warning' : 'success'}
              onClick={() => toggleIsSignatario(index)}
              disabled={signatario.readonly || isBusy}
              className="align-self-start"
            >
              {signatario.signatario ? 'Desmarcar como ' : 'Marcar como '}
              Signatário
            </Button>
          )}
        {edicaoAvancada &&
          podeRealizarAcaoPreAprovacao &&
          grupo.tipo === TiposGruposDocumentoDigital.Testemunhas &&
          !signatario.readonly && (
            <ButtonGroup className="w-100">
              <Button
                variant="outline-primary"
                onClick={() => beginEditarTestemunha(signatario)}
                disabled={isBusy || !incluirTestemunhas}
              >
                Editar
              </Button>
              <Button
                variant="outline-warning"
                onClick={() => toggleConfirmDeleteSignatario(index, true)}
                className={signatario.isDeleting ? 'd-none' : 'd-block'}
                disabled={isBusy || !incluirTestemunhas}
              >
                Excluir
              </Button>
              <Outsider
                className={signatario.isDeleting ? 'd-block' : 'd-none'}
                onClickOut={() => toggleConfirmDeleteSignatario(index, false)}
              >
                <Button
                  variant="danger"
                  onClick={() => removerSignatario(index)}
                  disabled={isBusy}
                >
                  Tem Certeza?
                </Button>
              </Outsider>
            </ButtonGroup>
          )}
        {grupo.tipo !== TiposGruposDocumentoDigital.Validadores &&
          (grupo.tipo !== TiposGruposDocumentoDigital.Compradores ||
            signatario.assina_contrato) &&
          signatario.signatario &&
          podeRealizarAcaoPreAprovacao && (
            <Form.Control
              as="select"
              value={signatario.exigir_documento_com_foto || 0}
              className="mt-1"
              onChange={e => setValidacaoSignatario(index, e.target.value)}
            >
              <option value={0}>Apenas assinatura</option>
              <option value={1}>Exigir doc. com foto</option>
            </Form.Control>
          )}

        {((podeGerenciar &&
          signatario.signatario &&
          !signatario.codigo_signatario_substituto &&
          signatario.data_inicio_vez &&
          !signatario.assinado) ||
          (habilitarNotificacao && !signatario.assinado)) && (
          <Button
            variant="primary"
            onClick={() => notificarSignatario(signatario.codigo)}
            disabled={isBusy}
            className="align-self-start ml-auto mb-1"
          >
            Notificar
          </Button>
        )}

        <ButtonGroup>
          {((podeGerenciar &&
            signatario.signatario &&
            (status === StatusDocumentoDigital.PendenteAssinaturasClientes ||
              status === StatusDocumentoDigital.PendenteAssinaturasSetpar) &&
            !signatario.codigo_signatario_substituto &&
            !signatario.assinado) ||
            (habilitarNotificacao && !signatario.assinado)) && (
            <Button
              variant="outline-primary"
              onClick={() => beginEditarSignatario(signatario)}
              disabled={isBusy}
              className="align-self-start ml-auto mb-1"
            >
              Editar
            </Button>
          )}
          {podeGerenciar &&
            status === StatusDocumentoDigital.PendenteAssinaturasClientes &&
            signatario.assina_contrato &&
            !signatario.is_cliente &&
            signatario.signatario &&
            !signatario.assinado && (
              <Button
                variant="outline-danger"
                onClick={() => deletarSignatario(signatario.codigo)}
                disabled={isBusy}
                className="align-self-start ml-auto"
              >
                Remover
              </Button>
            )}
        </ButtonGroup>
        {grupo.tipo === TiposGruposDocumentoDigital.Compradores &&
          !signatario.assina_contrato && (
            <small className="align-self-end text-right">
              Este cônjuge/sócio não está configurado para assinar o contrato.
            </small>
          )}
      </div>
      <ModalSignatario
        onSubmit={editarSignatario}
        signatario={signatarioSelecionado}
        show={showModalEditarSignatario}
        onClosing={() => {
          setSignatarioSelecionado({});
          setShowModalEditarSignatario(false);
        }}
      />
    </>
  );
};

export default EdicaoSignatario;
