import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Service from '../Services/Service';
import RecuperarSenha from '../Components/Login/RecuperarSenha/index';
import { default as auth } from '../Utils/AuthStorage';
import logoLightRevo from '../Images/logo-white.svg';
import { Button, Col, Card, Form } from 'react-bootstrap';
import { ENV, MASTER_VERSION } from '../constant';
import { toast } from 'react-toastify';

const mapStateToProps = state => ({
  userLogged: state.auth.user,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doLogin: auth => ({
        type: 'DO_LOGIN',
        payload: auth,
      }),
    },
    dispatch
  );

class Login extends Component {
  state = {
    usuario: '',
    senha: '',
    telefone: '',
    email: '',
    env: ENV[0].value,
    errors: {
      usuario: null,
      senha: null,
    },
    isLoading: false,
    errorLogin: null,
    isLogout: new URLSearchParams(this.props.location.search).get('logout'),
    isRecuperarSenha: new URLSearchParams(this.props.location.search).get(
      'recuperar-senha'
    ),
    isRecuperarUser: new URLSearchParams(this.props.location.search).get(
      'recuperar-user'
    ),
    isExpired: new URLSearchParams(this.props.location.search).get('expired'),
  };

  componentDidMount() {
    if (this.state.isLogout) {
      setTimeout(() => {
        this.setState({
          isLogout: false,
        });
      }, 2000);
    }
    if (this.state.isExpired) {
      setTimeout(() => {
        this.setState({
          isExpired: false,
        });
      }, 2000);
    }
  }

  handleChange = event => {
    let errors = this.state.errors;
    errors[event.target.name] = event.target.value ? false : true;

    this.setState({
      errors: errors,
      [event.target.name]: event.target.value,
    });
  };

  handleChangeEmail = event => {
    this.setState({
      email: event.target.value,
    });
  };
  handleChangeTelefone = event => {
    this.setState({
      telefone: event.target.value,
    });
  };

  doLogin = async e => {
    e.preventDefault();

    this.setState({
      errorLogin: null,
      isLoading: true,
      errors: {
        usuario: null,
        senha: null,
      },
    });

    if (this.state.isRecuperarSenha) {
      const objResponse = {
        nome_usuario: this.state.usuario,
      };

      try {
        await Service.recuperarSenha(objResponse);

        toast.success('Nova senha enviado nos contatos cadastrados!');
        setTimeout(() => {
          window.location.href = 'https://www.revobrasil.com.br/Extranet/Login.aspx';
        }, 1500);
      } catch (error) {
        console.error(error);
        toast.error('Erro ao recuperar senha!');
      }
      this.setState({
        isLoading: false,
      });
    }

    if (this.state.isRecuperarUser) {
      const objResponse = {
        email: this.state.email,
        cel: this.state.telefone,
      };
      try {
        const user = await Service.recuperarUser(objResponse);

        window.location.href = `https://www.revobrasil.com.br/Extranet/Login.aspx?user=${user}`;
      } catch (error) {
        console.error(error);
        toast.error(error.message);
      }
      this.setState({
        isLoading: false,
      });
    }

    if (!this.state.isRecuperarSenha || !this.state.isRecuperarUser) {
      if (!this.state.usuario || !this.state.senha) {
        let errors = this.state.errors;
        errors.usuario = this.state.usuario ? false : true;
        errors.senha = this.state.senha ? false : true;

        this.setState({
          errors: errors,
        });

        return;
      }

      Service.doLogin(
        {
          username: this.state.usuario,
          password: this.state.senha,
        },
        this.state.env
      )
        .then(response => {
          auth.setUser(response);
          return Service.getClaims()
            .then(claims => {
              auth.setUser({ ...response, ...claims.user_info });
              auth.setCaracteristicas(claims.caracteristicas);
              return response;
            })
            .catch(error => {
              throw error;
            });
        })
        .then(response => {
          // LocalStorage.set(constants.AUTH_KEY, response.access_token)
          // LocalStorage.set(constants.HN, response.hn)
          this.props.doLogin({
            ...response,
            username: this.state.usuario,
          });
        })
        .catch(error => {
          auth.removeToken();
          console.error(error);
          const errorMessage =
            error.error_description ||
            error.message ||
            error ||
            'Usuário ou senha inválido';
          this.setState({
            errorLogin: errorMessage,
            isLoading: false,
          });
        });
    }
  };

  render() {
    const {
      errorLogin,
      isLoading,
      isLogout,
      isExpired,
      isRecuperarSenha,
      isRecuperarUser,
    } = this.state;
    const { userLogged, location } = this.props;

    if (userLogged) {
      if (
        location.state &&
        location.state.from &&
        location.state.from.pathname
      ) {
        return <Redirect to={location.state.from} />;
      }

      return <Redirect to="/comercial/ranking/corretor" />;
    }

    return (
      <div className="Login">
        <Col
          md={{ span: 6, offset: 3 }}
          lg={{ span: 5, offset: 7 }}
          className="Login__right"
        >
          <div className="Login__header text-center text-light">
            <h3>
              <img
                className="Login__logo"
                alt="Revo Brasil"
                src={logoLightRevo}
              />
            </h3>
          </div>
          <Card className="border-0">
            <Card.Body>
              {isLogout && (
                <div className="alert alert-info">Você desconectou!</div>
              )}
              {isExpired && (
                <div className="alert alert-info">Sua sessão expirou!</div>
              )}
              {isRecuperarSenha && (
                <div className="alert alert-info">Recupere sua senha!</div>
              )}
              {isRecuperarUser && (
                <div className="alert alert-info">Recupere seu usuário!</div>
              )}
              <h4 className="mb-0 Login__card-header">
                Extranet <small className="text-muted">v{MASTER_VERSION}</small>
              </h4>
              <p className="text-secondary">
                {isRecuperarSenha && 'Informe o usuário para resgatar a senha'}
                {isRecuperarUser &&
                  'Informe o contatos nescessários para resgatar o seu usuário'}
                {!isRecuperarSenha &&
                  !isRecuperarUser &&
                  'Informe o usuário para resgatar a senha'}
              </p>
              <Form noValidate onSubmit={this.doLogin} className="form-signin">
                {!isRecuperarUser ? (
                  <>
                    <Form.Group controlId="login.user">
                      <Form.Label>Usuário</Form.Label>
                      <Form.Control
                        autoFocus
                        placeholder="Seu usuário"
                        name="usuario"
                        required={true}
                        onChange={this.handleChange}
                        disabled={isLoading}
                      />
                    </Form.Group>
                    <Form.Group controlId="login.password">
                      {!isRecuperarSenha && (
                        <>
                          <Form.Label>Senha</Form.Label>
                          <Form.Control
                            type="password"
                            name="senha"
                            placeholder="Sua senha"
                            required={true}
                            disabled={isLoading}
                            onChange={this.handleChange}
                          />
                        </>
                      )}
                    </Form.Group>
                  </>
                ) : (
                  <>
                    <RecuperarSenha
                      isLoading={isLoading}
                      handleChangeEmail={this.handleChangeEmail}
                      handleChangeTelefone={this.handleChangeTelefone}
                    ></RecuperarSenha>
                  </>
                )}
                <Form.Group>
                  <Form.Label>Ambiente</Form.Label>
                  <Form.Control
                    as="select"
                    name="env"
                    required={true}
                    disabled={isLoading}
                    onChange={this.handleChange}
                    defaultValue={ENV[0].value}
                  >
                    {ENV.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <div className="d-flex justify-content-between">
                  {isRecuperarSenha && (
                    <Form.Label className="">
                      <a href="?recuperar-user=true">Recuperar usuário</a>
                    </Form.Label>
                  )}
                  {!isRecuperarSenha && !isRecuperarUser && (
                    <Form.Label className="">
                      <a href="?recuperar-senha=true">Recuperar senha</a>
                    </Form.Label>
                  )}

                  <Button
                    type="submit"
                    variant="primary"
                    disabled={
                      isLoading ||
                      (!isRecuperarUser && !this.state.usuario) ||
                      (!isRecuperarSenha &&
                        !isRecuperarUser &&
                        !this.state.senha)
                    }
                  >
                    {isLoading
                      ? 'Carregando...'
                      : isRecuperarSenha || isRecuperarUser
                      ? 'Recuperar'
                      : 'Entrar'}
                  </Button>
                </div>

                {errorLogin && (
                  <div className="text-center text-danger mt-4">
                    {errorLogin}
                  </div>
                )}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
