import React from 'react';
import _groupBy from 'lodash/groupBy';

import { ESTADO_CIVIL_CS, ESTADO_CIVIL_CLIENTE, SEXO } from '~/constant';
import * as helpers from '~/Utils/Helpers';
import VerticalTimeline from '~/Components/VerticalTimeline';

import {
  ContainerResumeProspect,
  LineResume,
  TitleLineResume,
  DocumentList,
} from './styles';

export default function ResumeProspect({ prospecto }) {
  const documentos = _groupBy(prospecto.documentos, 'Tipo');

  return (
    <ContainerResumeProspect>
      <strong className="header-section">Perfil</strong>
      <ul>
        <LineResume>
          <TitleLineResume>Dados Pessoais</TitleLineResume>
          <ul>
            <li>
              <small>Telefone Celular</small>
              <strong>
                {prospecto.tel_celular
                  ? helpers.format.celular(prospecto.tel_celular)
                  : '-'}
              </strong>
            </li>
            <li>
              <small>Telefone Comercial</small>
              <strong>
                {prospecto.tel_comercial
                  ? helpers.format.celular(prospecto.tel_comercial)
                  : '-'}
              </strong>
            </li>
            <li>
              <small>Telefone Residencial</small>
              <strong>
                {prospecto.tel_residencial
                  ? helpers.format.celular(prospecto.tel_residencial)
                  : '-'}
              </strong>
            </li>
            <li>
              <small>E-mail</small>
              <strong>{prospecto.email || '-'}</strong>
            </li>
            <li>
              <small>CPF</small>
              <strong>
                {prospecto.cpf ? helpers.format.cpf(prospecto.cpf) : '-'}
              </strong>
            </li>
            <li>
              <small>Tipo do Cadastro</small>
              <strong>
                {prospecto.tipo_pessoa === 0
                  ? 'Pessoa Física'
                  : 'Pessoa Jurídica'}
              </strong>
            </li>
            <li>
              <small>Gênero</small>
              <strong>
                {prospecto.sexo
                  ? SEXO.find(item => item.value === prospecto.sexo).label
                  : '-'}
              </strong>
            </li>
            <li>
              <small>Estado Civil</small>
              <strong>
                {prospecto.estado_civil
                  ? ESTADO_CIVIL_CLIENTE.find(
                      item => item.value === Number(prospecto.estado_civil)
                    ).label
                  : '-'}
              </strong>
            </li>
            <li>
              <small>Data de nascimento</small>
              <strong>
                {prospecto.nascimento
                  ? helpers.format.date(prospecto.nascimento)
                  : '-'}
              </strong>
            </li>
            <li>
              <small>Profissão</small>
              <strong>{prospecto.descricao_profissao || '-'}</strong>
            </li>
            <li>
              <small>RG</small>
              <strong>{prospecto.rg || '-'}</strong>
            </li>
            <li>
              <small>Orgão emissor RG</small>
              <strong>{prospecto.orgao_emissor_rg || '-'}</strong>
            </li>
            <li>
              <small>Data Emissão RG</small>
              <strong>
                {prospecto.data_emissao_rg
                  ? helpers.format.date(prospecto.data_emissao_rg)
                  : '-'}
              </strong>
            </li>
          </ul>
        </LineResume>
        <LineResume>
          <TitleLineResume>Endereço</TitleLineResume>
          <ul>
            <li>
              <small>Endereço</small>
              <strong>{prospecto.endereco || '-'}</strong>
            </li>
            <li>
              <small>Número</small>
              <strong>{prospecto.numero || '-'}</strong>
            </li>
            <li>
              <small>Bairro</small>
              <strong>{prospecto.bairro || '-'}</strong>
            </li>
            <li>
              <small>Complemento</small>
              <strong>{prospecto.complemento || '-'}</strong>
            </li>
            <li>
              <small>CEP</small>
              <strong>
                {prospecto.cep ? helpers.format.cep(prospecto.cep) : '-'}
              </strong>
            </li>
            <li>
              <small>Cidade</small>
              <strong>{prospecto.cidade || '-'}</strong>
            </li>
            <li>
              <small>Estado</small>
              <strong>{prospecto.estado || '-'}</strong>
            </li>
          </ul>
        </LineResume>
        <LineResume>
          <TitleLineResume>Cônjuge/Sócio</TitleLineResume>
          {prospecto.conjuge_socio &&
            prospecto.conjuge_socio.length > 0 &&
            prospecto.conjuge_socio.map((socio, index) => (
              <ul key={index}>
                <li>
                  <small>Nome</small>
                  <strong>{socio.nome || '-'}</strong>
                </li>
                <li>
                  <small>Tipo</small>
                  <strong>
                    {socio.tipo_conjuge_socio
                      ? ESTADO_CIVIL_CS.find(
                          item =>
                            item.value === Number(socio.tipo_conjuge_socio)
                        ).label
                      : '-'}
                  </strong>
                </li>
                <li>
                  <small>Estado Civil</small>
                  <strong>
                    {
                      ESTADO_CIVIL_CLIENTE.find(
                        item => item.value === Number(socio.estado_civil)
                      ).label
                    }
                  </strong>
                </li>
              </ul>
            ))}
        </LineResume>
      </ul>

      <strong className="header-section">Documentos</strong>
      {Object.keys(documentos)[0] !== 'null' && (
        <ul>
          {Object.keys(documentos).map(Tipo => (
            <LineResume key={Tipo}>
              <TitleLineResume>Documentos</TitleLineResume>
              <DocumentList>
                {documentos[Tipo].map((documento, index) => (
                  <li key={index}>
                    {documento.descricao}
                    {documento.url && (
                      <a
                        href={documento.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={`Ver documento ${documento.descricao}`}
                      >
                        (ver documento)
                      </a>
                    )}
                  </li>
                ))}
              </DocumentList>
            </LineResume>
          ))}
        </ul>
      )}

      <strong className="header-section">Etapas Prospecto</strong>
      <VerticalTimeline etapas={prospecto.historico_fluxo} />
    </ContainerResumeProspect>
  );
}
