import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaInfoCircle, FaPrint } from 'react-icons/fa';

import * as api from '~/Services/Service';
import { format } from '~/Utils/Helpers';
import auth from '~/Utils/AuthStorage';

const EMPREENDIMENTOS_LANCAMENTO = 11452;

export default class HeaderProspectoDetalhes extends Component {
  state = {
    notificando: false,
    notificado: false,
    errorNotificar: null,
    data_reenvio: null,
  };

  imprimirFolhaDeConferencia = (ge, conta) => {
    this.setState({ buscandoFolhaConferencia: true });
    api
      .getFolhaConferenciaCliente(ge, conta)
      .then(response => {
        var win = window.open(
          '',
          'Folha de Conferência',
          'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=860,height=550'
        );
        win.document.body.innerHTML = response;
        this.setState({ buscandoFolhaConferencia: false });
      })
      .catch(error => {
        this.setState({ buscandoFolhaConferencia: false });
        console.error(error);
      });
  };

  onClickNotificar = async codigoProspecto => {
    this.setState({ notificando: true, errorNotificar: false });
    const { prospecto } = this.props;
    try {
      const data = await api.notificaCliente({
        codigo_prospecto: codigoProspecto,
        nome_cliente: prospecto.nome,
        email_cliente: prospecto.email,
        contato_cliente: prospecto.tel_celular,
        cpf: prospecto.cpf,
        codigo_familia: EMPREENDIMENTOS_LANCAMENTO,
      });
      this.setState({
        notificado: true,
        notificando: false,
        data_reenvio: format.datetime(data.data_ultima_notificacao),
      });
    } catch (error) {
      this.setState({
        notificando: false,
        errorNotificar: 'Erro ao notificar',
      });
    }
  };

  render() {
    const { prospecto } = this.props;
    const {
      codigo,
      codigo_familia,
      data_conferencia_cliente,
      senha_campanha_vinculo,
      data_ultima_notificacao,
    } = prospecto;

    const {
      notificado,
      notificando,
      buscandoFolhaConferencia,
      errorNotificar,
      data_reenvio,
    } = this.state;

    const backgroundColor = data_conferencia_cliente ? '#31ba5b' : '#ffe255';

    const getSituacaoClienteColor = situacaoCliente => {
      switch (situacaoCliente) {
        case 'Inadimplente':
          return 'red';
        case 'Adimplente':
          return 'green';
        case 'Cliente Novo':
          return 'blue';
        default:
          return 'black';
      }
    };

    const situacaoClienteColor = getSituacaoClienteColor(
      prospecto.situacao_cliente
    );

    if (!codigo) return <p className="m-0">Adicionando novo prospecto</p>;
    return (
      <div className="HeaderProspectoDetalhes mb-2">
        <small className="text-secondary d-block">Editando dados de</small>
        <strong className="h5">{prospecto.nome}</strong>
        {EMPREENDIMENTOS_LANCAMENTO === codigo_familia &&
          data_ultima_notificacao && (
            <small className="d-block font-weight-normal">
              <strong className="bullet mr-2" style={{ backgroundColor }} />

              {data_conferencia_cliente &&
                `Conferido ${format.datetime(data_conferencia_cliente)}`}

              {!data_conferencia_cliente && (
                <>
                  {auth.isCorretor() ? (
                    `Notificado ${format.datetime(data_ultima_notificacao)}`
                  ) : (
                    <>
                      {notificando && 'Notificando...'}

                      {!notificando && (
                        <OverlayTrigger
                          key="bottom"
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip-bottom">
                              {auth.isADM()
                                ? `senha atual: ${senha_campanha_vinculo}`
                                : `clique para reenviar`}
                            </Tooltip>
                          }
                        >
                          <button
                            className="BtnNotificar"
                            disabled={notificado || notificando}
                            onClick={() =>
                              this.onClickNotificar(prospecto.codigo)
                            }
                          >
                            {errorNotificar
                              ? `${errorNotificar} | Tentar novamente`
                              : `Notificado ${
                                  data_reenvio
                                    ? data_reenvio
                                    : format.datetime(data_ultima_notificacao)
                                }`}
                          </button>
                        </OverlayTrigger>
                      )}
                    </>
                  )}
                </>
              )}
            </small>
          )}

        <small className="d-flex align-items-center">
          Situação cliente:{' '}
          {prospecto ? (
            <strong style={{ marginLeft: '4px', color: situacaoClienteColor }}>
              {prospecto.situacao_cliente}
            </strong>
          ) : (
            '-'
          )}
          {prospecto && prospecto.situacao_cliente !== 'Cliente Novo' && (
            <a
              className="ml-2"
              href={`/cliente/pesquisa/situacao/${prospecto.cpf}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInfoCircle />
              <span className="d-none d-lg-inline">Detalhes</span>
            </a>
          )}
        </small>

        {prospecto.codigo_conta && (
          <small className="d-block">
            <button
              type="button"
              className="btn btn-sm btn-link p-0"
              onClick={() =>
                this.imprimirFolhaDeConferencia(
                  prospecto.codigo_grupo_empresa,
                  prospecto.codigo_conta
                )
              }
              disabled={buscandoFolhaConferencia}
            >
              <FaPrint />
              <span className="ml-1">
                {buscandoFolhaConferencia
                  ? 'Gerando folha de conferência'
                  : 'Folha de Conferência'}
              </span>
            </button>
          </small>
        )}
      </div>
    );
  }
}
